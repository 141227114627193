body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes loader-primary { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
@keyframes loader-secondary { 0% { transform: rotate(0deg); } 100% { transform: rotate(-360deg); } }
#uloader {
  --loader-size: 70px;
  --loader-margin-value: 5px;
  --loader-border-width: 2px;
  --loader-primary-color: #05264F;
  --loader-secondary-color: #008F8C;
  position: fixed;z-index: 1001;top: 50%;left: 50%;transform: translate(-50%, -50%);height: var(--loader-size);border-radius: 50%;aspect-ratio: 1 / 1;}
.uloader-primary, .uloader-secondary {position: absolute;border-radius: 50%;border: var(--loader-border-width) solid #000;aspect-ratio: 1 / 1;}
.uloader-primary {height: 100%;animation: loader-primary 5s linear infinite;border-color: var(--loader-primary-color) transparent var(--loader-primary-color) transparent;}
.uloader-secondary {height: calc(100% - 2*var(--loader-margin-value));animation: loader-secondary 0.6s linear infinite;border-color: transparent var(--loader-secondary-color) transparent var(--loader-secondary-color);margin: var(--loader-margin-value);}
